<template>
  <div class="login">
    <div class="login__containerLogin text-center widthControl">
      <ProfilePhoneVerification :userProfile="userProfile" :showVerification="true" :isSignup="true" />
    </div>
  </div>
</template>

<script>
import ProfilePhoneVerification from '@/components/Profile/ProfilePhoneVerification'
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'verifyPhone',
  components: {
    ProfilePhoneVerification
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
   
  },
}
</script>