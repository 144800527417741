<template>

      <div class="whiteBack mb-5 pb-5" :class="{ invalid: !userProfile.phoneVerified == 'approved' }">
      <!-- <div style="width:40%; min-width:300px; margin:auto;" class="whiteBack mt-5 pb-5"> -->
        <Loader v-if="performingRequest2" />




        <!-- NOT VERIFIED -->
        <div v-if="(!userProfile.phoneVerified || userProfile.phoneVerified != 'approved') && !showCodeInput">
          <label>Verify Your Cell Phone</label>
          <div>
            <input class="mt-3" autocomplete="one-time-code" v-model.trim="userProfile.phone" name="code" @keyup="showCodeSubmit()"/>
          </div>
          <button v-if="userProfile.phoneVerified != 'approved'" class="btn btn__primary mt-3" style="margin:auto;" @click="updatePhone()">Request Code
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest2">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </div>



        <!-- VERIFIED -->
        <div class="flex align-center justify-space-between" v-if="(userProfile.phoneVerified && userProfile.phoneVerified == 'approved') && !showCodeInput">
          
          <div class="flex align-center justify-space-between" style="width:100%;">
            <label>Cell Phone</label>
            <span v-if="userProfile.phoneVerified == 'approved'" class="caption">Verified <i style="color: green" class="fas fa-check ml-2"></i></span>
          </div>
        </div>
<!--         <div v-if="(userProfile.phoneVerified && userProfile.phoneVerified == 'approved') && showCodeInput">
        </div> -->
        <div v-if="(userProfile.phoneVerified && userProfile.phoneVerified == 'approved') && !showCodeInput && !isSignup">
          <div>
            <input v-model.trim="userProfile.phone" name="code" @keyup="showCodeSubmit()"/>
          </div>
          <button v-if="codeEntered" class="btn btn__primary mt-3" style="margin:auto;" @click="updatePhone()">Request New Code
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest2">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </div>

        <div v-if="(userProfile.phoneVerified && userProfile.phoneVerified == 'approved') && isSignup && !performingRequest2">
          <button class="btn btn__primary mt-3" style="margin:auto;" @click="handleOnComplete()">Continue
          </button>
        </div>



        <!-- <span v-if="changeNumber">
          <input class="mt-3" autocomplete="one-time-code" placeholder="******" v-model.trim="userProfile.phone" name="code" @keyup="showSubmit()"/>
        
          <button v-if="showSubmitButton" class="btn btn__primary mt-3" @click.prevent="updatePhone()">
            Submit
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest2">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </span> -->




        




        <!-- Verify Code -->
        <transition name="fade">
          <div v-if="showCodeInput">

            <!-- <div class="flex align-center justify-space-between">
              <div class="mb-4">{{userProfile.phone}}</div>
              <span v-if="userProfile.phoneVerified == 'approved'" class="caption mb-4 ml-3"><i style="color: green" class="fas fa-check"></i></span>
            </div> -->
            <label>Enter Code</label>

            <input class="mt-3" autocomplete="one-time-code" placeholder="******" v-model.trim="newCode" name="code" @keyup="watchCode()" @paste="watchCode()" />

            <button v-if="codeEntered" class="btn btn__primary mt-3" style="margin:auto;" @click="handleOnComplete()">Submit
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest2">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>

            <div class="flex justify-space-between" v-if="!performingRequest2 && !codeEntered" style="width:100%;">
              <button class="btn btn__outlined btn__small mt-5 mr-5" @click="handleClearInput()">Change Number</button>

              <button class="btn btn__dark btn__small mt-5" @click="updatePhone()">Request New Code</button>
            </div>
          </div>
        </transition>




        <!-- Button When Verify Error -->
        <transition name="fade">
          <div v-if="userProfile.phoneVerified && userProfile.phoneVerified == 'pending' && userProfile.phoneVerified != true">
            <button class="btn btn__outlined mt-3" @click.prevent="verify">
              Please check your code and try again
            </button>
          </div>
        </transition>





        <transition name="fade">
          <div v-if="error">
            <p>{{error}}</p>
          </div>
        </transition>

      </div>
</template>

<script>
import Loader from '@/components/Loader'
import { mapState } from 'vuex'
import router from '@/router'

export default {
  props: ['showVerification', 'isSignup'],

  components: {
    Loader
  },
  data() {
    return {
      changeNumber: false,
      performingRequest2: false,
      showSubmitButton: false,
      error: '',
      newCode: '',
      codeEntered: false,
      showCodeInput: true,
      // code: '',
    }
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
   approveCheck() {
    if (this.userProfile && this.userProfile.phoneVerified && this.userProfile.phoneVerified === 'approved') {
      return this.handleOnComplete()
    }
   },
  },
  methods: {
    watchCode() {
      if (this.newCode && this.newCode.length === 6) {
        return this.handleOnComplete()
      }
    },
    requestNewCode() {

    },
    showCodeSubmit() {
      this.codeEntered = true
    },
    showSubmit() {
      this.showSubmitButton = true
    },
    verify: function (value) {
      console.log(value)
      this.error = ''
      this.performingRequest2 = true
      this.$store.dispatch('updateUser', {
        code: value,
        phoneVerified: ''
      })
      setTimeout(() => {
        return this.approvalCheck()
      }, 5000)
    },
    approvalCheck() {
      console.log(this.userProfile.phoneVerified)
      if (this.userProfile && this.userProfile.phoneVerified && this.userProfile.phoneVerified === 'approved') {
        // this.showVerification = false,
        this.performingRequest2 = false,
        this.resetSubmission = true,
        this.changeNumber = true,
        this.showSubmitButton = false,
        this.newCode = '',
        this.codeEntered = false
        this.showCodeInput = false
        this.$router.push('/auth/complete')
      } else {
        console.log('clearing')
        this.handleClearInputOnly()
      }
    },
    updatePhone () {
      let userProfile = this.userProfile
      this.performingRequest2 = true
      let phone = this.userProfile.phone
      // this.codeEntered = true
      // this.$store.dispatch('updateUser', userProfile)
      this.$store.dispatch('updateUser', {
        phone: phone,
        phoneVerified: '',
        code: ''
      })
      setTimeout(() => {
          this.showCodeInput = true
          this.performingRequest2 = false
          this.$store.dispatch('updateUserNewVerifyRequest')
          this.resetSubmission = true
      }, 1000)
    },
    handleNewRequest() {
      console.log('sending')
      this.performingRequest2 = true
      this.$store.dispatch('updateUserNewVerifyRequest')
      setTimeout(() => {
          this.performingRequest2 = false
      }, 3000)
    },
    async handleOnComplete() {
      this.performingRequest2 = true
      // console.log('OTP completed: ', value);
      await this.verify(this.newCode)
      if (this.isSignup && this.userProfile.phoneVerified === 'approved') {
        this.$router.push('/auth/complete')
      } else {}
    },
    handleOnChange(value) {
      console.log('OTP changed: ', value);
    },
    handleClearInputOnly() {
      // this.$refs.otpInput.clearInput();
      // this.showVerification = true
      this.showCodeInput = false
      this.newCode = ''
      this.performingRequest2 = false
    },
    handleClearInput() {
      // this.$refs.otpInput.clearInput();
      this.showCodeInput = false
      this.$store.dispatch('updateUser', {
        phoneVerified: null,
        code: ''
      })
    },
  },
  beforeDestroy() {
    this.performingRequest2 = false
    delete this.performingRequest2
  }
}
</script>